import React, { useState, useEffect, useCallback, Suspense } from "react";
import { Route, Switch, Link, useHistory } from "react-router-dom";
import axios from "axios/index";
import {
  UncontrolledTooltip
} from "reactstrap";
import moment from 'moment';

import { isMobile } from 'react-device-detect';

// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";

// core components
// import AdminNavbar from "../../components/Navbars/AdminNavbar.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Sidebar from "../../components/Sidebar/Sidebar.jsx";
import LeftSidebar from '../../components/Sidebar/LeftSidebar';
// import FixedPlugin from "../../components/FixedPlugin/FixedPlugin.jsx";
import { routes } from "../../routes.js";
import Blade from "../../components/Blade/Blade";
import NotificationsFeed from '../../components/Notifications/NotificationsFeed';
import { appState } from "../../AppState";
import { LightEdgeNotificationsProvider } from "../../hooks/useLightEdgeNotifications.js";
import { SocketProvider, useSocket } from '../../hooks/SocketHook';
import NotFound from "../../components/NotFound";

import { TicketTabIndicator } from "../../components/Dashboard/TabbedDashboard.jsx";
import AppAlert from "../../components/AppAlert/AppAlert.jsx";
import Clickstream from "../../components/Clickstream/Clickstream.jsx";
import Enum from "../../utility/enum.js";
import useAsyncEffect from "../../utility/use-async-effect";
import SpoofBanner from "../../utility/SpoofBanner.js";
import ConnectriaAnnoucement from "../../utility/ConnectriaAnnoucment.js";
import { SessionTimeoutModal, HamburgerMenu } from "../../components/misc.jsx";
import ContentBlade from '../../components/Blade/ContentBlade';
import CookieService from "../../services/CookieService";
import PolicyToggle from "../../utility/PolicyToggle.js";

import MyLightEdgeLogo from "../../assets/img/my-le-logo.png";
import { OrdersProvider } from "../../hooks/useOrders.js";

export const App = (props) => {
  //local state
  const [sidebarOpened, setSidebarOpened] = useState(document.documentElement.className.indexOf("nav-open") !== -1);
  const [leftSidebarOpened, setLeftSidebarOpened] = useState(!isMobile); // useState(!!document.querySelector('.left-sidebar.expanded'));
  const [backgroundColor, setBackgroundColor] = useState("primary");
  const [burgerCssClass, setBurgerCssClass] = useState("burger-container");

  const [customerMessage, setCustomerMessage] = useState(null);
  const source = axios.CancelToken.source();

  //global state
  const [stateApp, stateAppActions] = appState();
  const history = useHistory();
  const {
    isOpen: isSocketOpen, send: sendSocketMessage, connect, disconnect, addMessageHandler, removeMessageHandler,
  } = useSocket();

  //un-comment below if we have issues with IE's scrollbar
  //componentDidMount -- notice second arg empty array 
  // useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     document.documentElement.className += " perfect-scrollbar-on";
  //     document.documentElement.classList.remove("perfect-scrollbar-off");
  //     ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
  //     let tables = document.querySelectorAll(".table-responsive");
  //     for (let i = 0; i < tables.length; i++) {
  //       ps = new PerfectScrollbar(tables[i]);
  //     }
  //   }
  // }, []);

  // //componentDidUpdate
  // useEffect(() => {
  //   if (e.history.action === "PUSH") {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       let tables = document.querySelectorAll(".table-responsive");
  //       for (let i = 0; i < tables.length; i++) {
  //         ps = new PerfectScrollbar(tables[i]);
  //       }
  //     }
  //     document.documentElement.scrollTop = 0;
  //     document.scrollingElement.scrollTop = 0;
  //     this.refs.mainPanel.scrollTop = 0;
  //   }
  //   //old componentWillUnmount support.. might need a different approach if we see perf issues
  //   return () => {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //       document.documentElement.className += " perfect-scrollbar-off";
  //       document.documentElement.classList.remove("perfect-scrollbar-on");
  //     }
  //   }
  // });

  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    if (!sidebarOpened) {
      setBurgerCssClass("burger-container burger-change");
    } else {
      setBurgerCssClass("burger-container");
    }
    setSidebarOpened(!sidebarOpened);
  };

  const toggleLeftSidebar = (open) => setLeftSidebarOpened(typeof open === 'boolean' ? open : o => !o);

  //get the raw routes (excluding groups)
  //only support one level of hierarchy
  const rawRoutes = () => {
    let result = [];
    for (var i = 0; i < routes.length; i++) {
      if (routes[i].group) {
        for (var ii = 0; ii < routes[i].routes?.length; ii++) {
          result.push(routes[i].routes[ii]);
        }
      } else {
        result.push(routes[i]);
      }
    }
    return result;
  }

  const handleSocketMessage = useCallback((message) => {
    console.log('new message on app: ', message);
    if (message.type === 'feed') {
      stateAppActions.setNotificationsList(message.data);
    }
  }, []);

  useEffect(() => {
    history.listen((location) => {
      stateAppActions.setPageNavTitle("");
      stateAppActions.setPageBackTitle("");

      // Refresh session timeout time when user goes to a new page
      if (history.location.pathname !== "/login") {
        var d = new Date();
        d.setMinutes(d.getMinutes() + 720);
        CookieService.setCookie("session_timeout_time", d.getTime(), 1, stateApp.env);
        stateAppActions.setSessionTimeoutTime(d.getTime());
      }
    });

    // initiate web socket
    connect();

    return () => {
      disconnect();
    }
  }, []);

  useEffect(() => {
    console.log('adding socket messgae handler from app.. ');
    addMessageHandler('app', handleSocketMessage);

    return () => {
      removeMessageHandler('app');
    }
  }, [handleSocketMessage, addMessageHandler, removeMessageHandler]);

  useEffect(() => {
    if (isSocketOpen) { // 1 means connection open
      console.log('sending feed message...');
      sendSocketMessage({ action: 'feed' });
    }
  }, [isSocketOpen, sendSocketMessage]);

  useAsyncEffect(
    async isMounted => {
        try {
        

            //let message = await OrganizationService.customer_message();
            
            if (!isMounted()) return;

            // add resource properties to application state
            // let properties = {}
            // for (let resProperty of resourceProps.data) {
            //   properties[resProperty.name] = resProperty.value
            // }
            // stateAppActions.setResourceProperties(properties)

            // if (message.data && message.data.template) {
            //   let replaceIndex = 0;
            //   const formattedMessage = message.data.template.replace(/<([^>]+)>/g, (match, label) => {
            //       if (!message.data.links || replaceIndex >= message.data.links.length) {
            //           return match;
            //       }
            //       const _link = `<a href="${message.data.links[replaceIndex]}" target="_blank">${label}</a>`;
            //       replaceIndex += 1;
            //       return _link;
            //   });
            //   setCustomerMessage(formattedMessage);
            // }
        } catch (error) {
            if (axios.isCancel(error)) {
                // request cancelled
            } else {
                throw error;
            }
        }
    },
    () => {
        source.cancel();
    },
    []
  );

  const hasNewNotification = stateApp.notifications?.list?.length > 0 &&
    moment().diff(moment(stateApp.notifications?.list[0].timestamp), 'hours') <= 24;

  return (
    <>
    {/* <CookieHubComponent></CookieHubComponent> */}
      <SpoofBanner></SpoofBanner>
      <div className="wrapper">
        <LeftSidebar
          isOpen={leftSidebarOpened}
          setIsOpen={setLeftSidebarOpened}
          routes={routes}
        />
        {stateApp.env === Enum.Env.DEV &&
          <Sidebar
            {...props}
            routes={routes}
            bgColor={backgroundColor}
            toggleSidebar={toggleSidebar}
          />}
        <div
          className="main-panel"
          // ref="mainPanel"
          data={backgroundColor}
        >
          <ConnectriaAnnoucement></ConnectriaAnnoucement>
          <AppAlert 
            visible={stateApp.alert.visible} 
            className={stateApp.alert.className}
            color={stateApp.alert.color}
          >
            {stateApp.alert.content}
          </AppAlert>
          {customerMessage &&
            <div className="alert alert-danger mb-0 rounded-0" role="alert" dangerouslySetInnerHTML={{ __html: customerMessage }} />
          }
          <div style={{backgroundColor: "#FFFFFF"}}>
            <div className="container-fluid">
              <div className="row py-2 mx-0">
                <div className="col-4 col-xl-auto">
                  <div className="d-flex ml-2 h-100 align-items-center">
                    <div className="d-block d-xl-none mr-2">
                      {!leftSidebarOpened && <HamburgerMenu onClick={toggleLeftSidebar}/>}
                    </div>
                    <h4 className="m-0 app-back-title">{stateApp.pageBackTitle}</h4>
                  </div>
                </div>
                <div className="col text-center text-xl-left">
                  <Link to="/app/dashboard" className="d-none d-md-inline">
                    <img className="logo-image my-2" src={MyLightEdgeLogo} alt="LightEdge Logo" />
                  </Link>
                  <div className="h-100 d-flex d-md-none align-items-center">
                    <h4 className="app-nav-title flex-fill m-0">{stateApp.pageNavTitle}</h4>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex mr-2 h-100 align-items-center justify-content-end">
                  <PolicyToggle policies={[Enum.Policies.NOTIFICATIONS_VIEW]}>
                    <div
                      to="/app/notifications"
                      className="d-none d-md-inline pr-4 position-relative cursor"
                      onClick={() => stateAppActions.setNotificationsVisible(true)}
                    >
                      <i className="far fa-bell" id="notifications-icon" style={{fontSize: "30px", color: "#BFC5D2"}}></i>
                      <UncontrolledTooltip placement="bottom" target="notifications-icon">
                        Alerts
                      </UncontrolledTooltip>
                      <TicketTabIndicator enabled={hasNewNotification} style={{ top: 0, left: 18 }}/>
                    </div>
                    </PolicyToggle>
                    <PolicyToggle policies={[Enum.Policies.TICKETS_VIEW]}>
                    <Link to="/app/support" className="d-none d-md-inline pr-4 position-relative">
                      <i className="fal fa-headset" id="support-ticketing-icon" style={{fontSize: "30px", color: "#BFC5D2"}}></i>
                      <UncontrolledTooltip placement="bottom" target="support-ticketing-icon">
                        Support & Ticketing
                      </UncontrolledTooltip>
                      <TicketTabIndicator enabled={stateApp.inboxCount > 0} style={{ top: 0, left: 23 }}/>
                    </Link>
                    </PolicyToggle>
                    <Link to="/app/contact" className="d-none d-md-inline pr-4">
                      <i className="fal fa-phone-volume" id="contact-us-icon" style={{fontSize: "30px", color: "#BFC5D2"}}></i>
                      <UncontrolledTooltip placement="bottom" target="contact-us-icon">
                        Contact Us
                      </UncontrolledTooltip>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content position-relative" id="content">
            <Suspense fallback={<div></div>}>
              <Switch>
                    {rawRoutes().filter(e => ((e.envs || []).includes(Enum.Env.ALL) || (e.envs || []).includes(stateApp.env))).map(({component: Cmp, ...route}, i) => {
                      
                        return (
                            <Route
                              key={i}
                              {...route}
                              path={route.layout + (route.path || '')}
                              component={route.component}
                              render={props => <Clickstream meta={route.clickstreamMeta}><Cmp {...route.props || {}} {...props} /></Clickstream>} 
                            />
                        );
                    })}
                    
                    <Route component={NotFound} />
              </Switch>
            </Suspense>
            {// we don't want the Footer to be rendered on map page
            props.location.pathname.indexOf("maps") !== -1 ? null : (
              <Footer fluid />
            )}
            {stateApp.notifications?.visible &&
              <NotificationsFeed onToggle={() => stateAppActions.setNotificationsVisible(false)} />
            }
          </div>
          <Blade 
            title={stateApp.blade.title} 
            visible={stateApp.blade.visible} 
            setBladeState={stateAppActions.setBlade}>
              {stateApp.blade.content}
          </Blade>
        </div>
        <ContentBlade
          visible={stateApp.contentBlade.visible}
          className={stateApp.contentBlade.className}
          headerClassName={stateApp.contentBlade.headerClassName}
          title={stateApp.contentBlade.title}
          footer={stateApp.contentBlade.footer} 
          side={stateApp.contentBlade.side}
          noBackdrop={stateApp.contentBlade.noBackdrop}
          setPanelState={stateAppActions.setContentBlade}
          style={stateApp.contentBlade.style}
        >
          {stateApp.contentBlade.content}
        </ContentBlade>
      </div>
      <SessionTimeoutModal/>
      {/* <FixedPlugin
        bgColor={this.state.backgroundColor}
        handleBgClick={this.handleBgClick}
      /> */}
    </>
  );
}

const AppWrapper = (props) => (
  <SocketProvider>
    <LightEdgeNotificationsProvider>
      <OrdersProvider>
        <App {...props} />
        </OrdersProvider>
    </LightEdgeNotificationsProvider>
  </SocketProvider>
);

export default AppWrapper;