import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Button, Badge } from "reactstrap";

export const OrderStatus = Object.freeze({
  ORDER_RECEIVED: 0,
  ORDER_REVIEW: 1,
  ASSEMBLING_PROJECT_TEAM: 2,
  PROJECT_KICK_OFF: 3,
  BUILDING_YOUR_ENVIRONMENT: 4,
  AWAITING_YOUR_INPUT: 5,
  BURN_IN_TESTING_AND_QUALITY_ASSURANCE: 6,
  ORDER_COMPLETE: 7,
  CLOSED: 8,
});

const badgeStyle = {
  fontSize: "12px",
  width: "110px",
  backgroundColor: "#E0F7FA",
  color: "#00ACC1",
  border: "1px solid #00ACC1",
};

const buttonStyle = {
  fontSize: "12px",
  borderRadius: "20px",
};

const maxDescriptionLength = 100; // characters

const getBadgeLabel = (status) => {
  if (status === "CLOSED" && status !== "ORDER_COMPLETE") {
    return "Closed";
  }
  if (status === "AWAITING_YOUR_INPUT") {
    return "Action Required";
  }
  const numericStatus = OrderStatus[status];
  return numericStatus < OrderStatus.ORDER_COMPLETE
    ? "In Process"
    : "Completed";
};

const OrderItem = ({ order, isDashboard }) => {
  const history = useHistory();
  const { id, description, status_customer_facing } = order;

  const badgeLabel = useMemo(
    () => getBadgeLabel(status_customer_facing),
    [status_customer_facing]
  );

  const computedBadgeStyle = useMemo(() => {
    if (status_customer_facing === "AWAITING_YOUR_INPUT") {
      return {
        fontSize: "12px",
        width: "110px",
        backgroundColor: "#FFEBEE",
        color: "#F44336",
        border: "1px solid #F44336",
      };
    }
    return badgeStyle;
  }, [status_customer_facing]);

  const handleTrackOrder = useCallback(() => {
    history.push(`/app/order/${id}`);
  }, [history, id]);

  const truncatedDescription = useMemo(() => {
    if (isDashboard && description.length > maxDescriptionLength) {
      return `${description.substring(0, maxDescriptionLength)}...`;
    }
    return description;
  }, [description, isDashboard]);

  return (
    <div className="mb-4 border-bottom pb-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <h5 className="mb-0 text-primary font-weight-bold">Order #{id}</h5>
          <p>{truncatedDescription}</p>
        </div>
        <Badge className="p-2" style={computedBadgeStyle}>
          {badgeLabel}
        </Badge>
      </div>
      <div>
        <Button
          onClick={handleTrackOrder}
          color="link"
          className="border border-info"
          style={buttonStyle}
        >
          Track Order
        </Button>
      </div>
    </div>
  );
};

OrderItem.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    description: PropTypes.string,
    status_customer_facing: PropTypes.string.isRequired,
  }).isRequired,
  isDashboard: PropTypes.bool,
};

export default OrderItem;
