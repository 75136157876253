import axios from "axios";
import Cognito from "../utility/CognitoAuth";
import CookieService from "./CookieService";

const base_api =
  "https://4jsb2f47s8.execute-api.us-east-1.amazonaws.com/dev/api";

async function defaultRequest() {
  const idToken = await Cognito.getIdToken();
  let req = axios.create({
    baseURL: base_api,
    headers: { Authorization: idToken },
  });

  return req;
}

const YetiOrders = {
  async getOrders() {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");

    const queryParams = new URLSearchParams({ cache: "1" });

    return req.get(
      `${base_api}/${orgId}/orders/orderlist?${queryParams.toString()}`
    );
  },

  async getOrder(
    orderId,
    filters = { status: ["!CLOSED"], type: ["!TERMINATION", "!DOWNGRADE"] }
  ) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");

    const queryParams = new URLSearchParams({
      id: orderId,
      cache: "1",
    });

    if (filters.status)
      filters.status.forEach((status) =>
        queryParams.append("status[]", status)
      );
    if (filters.type)
      filters.type.forEach((type) => queryParams.append("type[]", type));

    return req.get(
      `${base_api}/${orgId}/orders/order?${queryParams.toString()}`
    );
  },

  async getOrderComments(orderId) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");

    return req.get(
      `${base_api}/${orgId}/orders/commentlist?cache=1&order_id=${orderId}`
    );
  },

  async postOrderComment(orderId, message, attachment) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");

    const data = new FormData();
    data.append("order_id", orderId);
    data.append("message", message);
    if (attachment) data.append("attachment", attachment);

    return req.post(`${base_api}/${orgId}/orders/comment`, data);
  },

  async getOrderAttachments(orderId) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");

    return req.get(
      `${base_api}/${orgId}/orders/attachmentlist?cache=1&order_id=${orderId}`
    );
  },

  async getAttachment(attachment_id, order_id) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    const response = await req.get(
      `${base_api}/${orgId}/orders/attachment?attachment_id=${attachment_id}&order_id=${order_id}`,
      {
        headers: { Accept: "application/octet-stream" },
      }
    );
    return {
      data: Buffer.from(response.data || "", "binary").toString("base64"),
    };
  },

  async createOrderAttachment(order_id, contact_id, name, filename, document) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.post(base_api + `/${orgId}/orders/attachment`, {
      order_id,
      contact_id,
      name,
      filename,
      document,
    });
  },

  async createOrderComment(order_id, author_id, message) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.post(base_api + `/${orgId}/orders/comment`, {
      order_id,
      author_id,
      message,
    });
  },

  async getOrderLineItems(
    orderId,
    filters = { signed: ["TRUE"], charge_type: ["!ONE-TIME"] }
  ) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");

    const queryParams = new URLSearchParams({
      cache: "1",
      order_id: orderId,
    });

    if (filters.signed)
      filters.signed.forEach((signed) =>
        queryParams.append("signed[]", signed)
      );
    if (filters.charge_type)
      filters.charge_type.forEach((type) =>
        queryParams.append("charge_type[]", type)
      );

    return req.get(
      `${base_api}/${orgId}/orders/lineitemlist?${queryParams.toString()}`
    );
  },

  async getOrderDetails(orderId) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");

    const queryParams = new URLSearchParams({
      cache: "1",
      order_id: orderId,
    });

    queryParams.append("signed[]", "TRUE");
    queryParams.append("charge_type[]", "!ONE-TIME");

    return req.get(
      `${base_api}/${orgId}/orders/lineitemlist?${queryParams.toString()}`
    );
  },

  async getLightEdgeContact(employeeId) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    const queryParams = new URLSearchParams({
      id: employeeId,
      cache: "1",
    });

    return req.get(
      `${base_api}/${orgId}/contacts/employee?${queryParams.toString()}`
    );
  },
};

export default YetiOrders;
