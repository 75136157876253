import React, { useCallback, useState, useMemo } from "react";
import { Card, Spinner, Button } from "reactstrap";
import OrderItem from "./OrderItem";
import { useOrders } from "../../hooks/useOrders";
import PaginationControls from "../PaginationControls/PaginationControls";

const Orders = ({ isDashboard }) => {
  const { orders, loading, error, fetchOrders } = useOrders();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalItems = orders.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const paginatedOrders = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return orders.slice(startIndex, endIndex);
  }, [orders, currentPage, itemsPerPage]);

  const handleNextPage = useCallback(() => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  }, [totalPages]);

  const handlePreviousPage = useCallback(() => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  }, []);

  return (
    <Card className="p-4 mt-2 text-left">
      <h3>Orders</h3>

      {/* Loading */}
      {loading && (
        <div className="text-center my-3">
          <Spinner color="primary" />
          <p>Loading orders...</p>
        </div>
      )}

      {/* Error */}
      {error && (
        <div className="text-center text-danger my-3">
          <p>{error}</p>
          <Button color="primary" onClick={fetchOrders}>
            Retry
          </Button>
        </div>
      )}

      {/* List */}
      {!loading && !error && orders.length > 0 ? (
        <div>
          {paginatedOrders.map((order) => (
            <OrderItem key={order.id} order={order} isDashboard={isDashboard} />
          ))}
          <div className="d-flex justify-content-center">
          <PaginationControls
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            itemsName="Orders"
            />
          </div>
        </div>
      ) : (
        !loading && !error && <p>No orders available.</p>
      )}
    </Card>
  );
};

export default Orders;
