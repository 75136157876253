function parseQueryParams() {
  var queryDict = {};
  window.location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      queryDict[item.split('=')[0]] = item.split('=')[1];
    });
  return queryDict;
}
function buildRurl() {
  if (
    window.location.pathname == null ||
    window.location.pathname.length == 0 ||
    window.location.pathname == '/' ||
    window.location.href.indexOf('rurl') > -1
  ) {
    return '';
  }
  //is there already a rurl? if so, use it.
  var queryParams = parseQueryParams();
  if (queryParams['rurl'] != null) return queryParams['rurl'];
  //build new rurl
  var rurl = '?rurl=' + window.location.pathname;
  if (window.location.search != null && window.location.search.length > 0) {
    return rurl + '?' + window.location.search;
  }
  return rurl;
}

function range(start, end) {
  let result = [];
  for (var i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function autosize() {
  // Computes new height for textboxes when user is typing so they can expand
  var el = this;
  setTimeout(function () {
    el.style.height = 'inherit';

    // Get the computed styles for the element
    var computed = window.getComputedStyle(el);

    // Calculate the height
    var height =
      parseInt(computed.getPropertyValue('border-top-width'), 10) +
      parseInt(computed.getPropertyValue('padding-top'), 10) +
      el.scrollHeight +
      parseInt(computed.getPropertyValue('padding-bottom'), 10) +
      parseInt(computed.getPropertyValue('border-bottom-width'), 10);

    el.style.height = height + 'px';
  }, 0);
}

function getDateString() {
  const date = new Date();
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, '0');
  const day = `${date.getDate()}`.padStart(2, '0');
  var minutes = date.getMinutes();
  var hour = date.getHours();
  return `_${year}${month}${day}_${hour}:${minutes}`;
}
function text_truncate(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}
function insertString(a, b, at) {
  var position = a.lastIndexOf(at);

  if (position !== -1) {
    return a.substr(0, position) + b + a.substr(position);
  }

  return a;
}
const is_before_date = (date1, date2) => date1 < date2;

export { parseQueryParams, range, autosize, buildRurl, getDateString, insertString, text_truncate, is_before_date };

export const location_map = {
    "290": "Austin",
    "909": "Des Moines",
    "ALN1": "Altoona",
    "ALN2": "Altoona",
    "AMS1": "Amsterdam",
    "AUS1": "Austin",
    "AUS2": "Austin",
    "CAV1": "Lenexa",
    "CAV2": "Lenexa",
    "DESMIA72": "Des Moines",
    "DFW1": "Lewisville",
    "EPRC": "Altoona",
    "EQXCH1": "Chicago",
    "EQXDA3": "Dallas",
    "IAD1": "Ashburn",
    "KCSALES": "Overland Park",
    "KSCYMOAV": "Kansas City",
    "LAX1": "Los Angeles",
    "MSP1": "Chaska",
    "OLIVE": "St Louis",
    "OMAHNE16": "Omaha",
    "OMAHNE68": "Omaha",
    "PHX1": "Phoenix",
    "PPL": "Papillion",
    "RDU": "Raleigh",
    "SAN1": "San Diego",
    "SAN2": "San Diego",
    "SIN1": "Singapore",
    "SIN2": "Singapore",
    "SJC1": "San Jose",
    "STC": "Kansas City",
    "STCRC": "Kansas City",
    "STL1": "St. Louis",
    "STL2": "St. Louis"
};

// Color scheme for data sources
export const SOURCE_COLORS = {
  source1: '#105c7a',
  source2: '#ffbc19',
  source3: '#99c525',
  source4: '#663d66',
  source5: '#10b1da',
  source6: '#505a60',
  source7: '#89b9cc',
  source8: '#ffd675',
  source9: '#c1dc7c',
  source10: '#b29eb2',
  source11: '#87d8ec',
  source12: '#a7acaf',
  Total: '#663d66',
  Purchased: '#10b1da'
};
