import YetiClient from "./YetiClient";

const YetiService = {
  serviceList: async function (filters = {}, cache = 1) {
    const availableFilters = ["status", "type_id", "configuration_item_id"];
    const config = {
      params: {
        cache: cache,
      }
    };

    // TODO this is the way once array support is added to the YETI proxy Lambda
    // availableFilters.forEach((key) => {
    //   if (filters.hasOwnProperty(key)) {
    //     config.params[key] = filters[key];
    //   }
    // });
    // return YetiClient.getPaginated("/services/servicelist", config);

    // TODO filter locally until array support is added to the YETI proxy Lambda
    const response = await YetiClient.getPaginated("/services/servicelist", config);

    if (response?.data?.results) {
      let filteredResults = response.data.results;
      availableFilters.forEach((key) => {
        if (filters.hasOwnProperty(key)) {
          filteredResults = filteredResults.filter(item => filters[key].includes(item[key]));
        }
      });
      return {
        ...response,
        data: {
          ...response.data,
          results: filteredResults
        }
      };
    }

    return response;
  },
  subServiceList: async function (serviceId, cache = 1) {
    const config = {
      params: {
        service_id: serviceId,
        cache: cache,
      },
    };
    return YetiClient.get("/services/subservicelist", config);
  },
  getService: async function (serviceId, cache = 1) {
    const config = {
      params: {
        service_id: serviceId,
        cache: cache,
      },
    };
    return YetiClient.get("/services/service", config);
  },
};

export default YetiService;
