import React from "react";
import { Button } from "../Marketing/Button/Button";

const PaginationControls = ({
  currentPage,
  itemsPerPage,
  totalItems,
  handlePreviousPage,
  handleNextPage,
  itemsName = "Records",
}) => {
  return (
    <div className="pagination-controls">
      <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
        Previous
      </Button>

      <span className="mx-2">
        Page {currentPage} of {Math.ceil(totalItems / itemsPerPage)} | Total{" "}
        {itemsName}: {totalItems}
      </span>

      <Button
        onClick={handleNextPage}
        disabled={currentPage >= Math.ceil(totalItems / itemsPerPage)}
      >
        Next
      </Button>
    </div>
  );
};

export default PaginationControls;
