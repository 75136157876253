import React from "react";
import { useEffect, useState, useContext, createContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import { appState } from "../../AppState";
import SolarwindService from '../../services/Solarwind';
import ConnectriaTicketsService from '../../services/ConnectriaTicketsService';
import axios from "axios";
import useAsyncEffect from "../../utility/use-async-effect";
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Collapse, Input, UncontrolledTooltip, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { PieChart, Pie, Sector, Cell, Label, ResponsiveContainer } from "recharts";
import { TriaCard, CardFieldValue } from "../Cards/TriaCard";

import { isMobile, isTablet, isMobileOnly } from "react-device-detect";

import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import DropdownList from 'react-widgets/lib/DropdownList'

import { ButtonDropdown } from '../Buttons/ButtonDropdown';
import { ToggleSwitch } from '../InputComponents/ToggleSwitch';
import 'react-widgets/dist/css/react-widgets.css';

moment.locale('en')
momentLocalizer()


const DeviceDetailPage = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setError] = useState(false);
  const history = useHistory();
  const source = axios.CancelToken.source();

  const [pageExpanded, setPageExpanded] = useState(false);
  const [nodeDetail, setNodeDetail] = useState({
    metrics: {},
    properties: {},
  });
  const [currentMaintenanceWindow, setCurrentMaintenanceWindow] = useState({});
  const [assetTag, setAssetTag] = useState(props.history.location.state?.assetTag || "");
  const deviceId = props.match.params?.id || "";
  const [diskInfo, setDiskInfo] = useState(null);
  const toggleExpandPage = (e) => {e.preventDefault(); setPageExpanded(!pageExpanded);}

  useAsyncEffect(
    async isMounted => {
      try {
        setIsLoading(true);
        setError(false);
        setAssetTag(props.history.location.state?.assetTag || "");
        let volumeDetail = null;
        let deviceDetail = await ConnectriaTicketsService.getDevice(deviceId);
        const ipAddresses = deviceDetail = deviceDetail?.devices?.[0]?.address_tags?.map(addr => addr.address) || [];
        const nodeDetail = await SolarwindService.device_detail(deviceId);
        
        try{
          //wrapped in try do to only disk info will come back if a device has a disk.. i.e Network devices will not.
          volumeDetail =  null;//await SolarwindService.get_device_volume_info(deviceId);
        }catch(e){
          console.error(e)
        }

        if (!isMounted()) return;
        
        setNodeDetail({ ...nodeDetail.data, ipAddresses });
        setCurrentMaintenanceWindow(nodeDetail.data.properties.currentMaintenanceWindow);
        setAssetTag(nodeDetail.data.properties.information.AssetTag);
        if(volumeDetail != null) setDiskInfo(volumeDetail.data)
        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log(error);
        } else {
          console.log(error);
          setError(true);
        }
        setIsLoading(false);
      }
    },
    () => {
      source.cancel();
    },
    [props.match.params.id]
  );

  const createNewTicket = (e) => {
    if (e != null) {
        e.preventDefault();
    }
    history.push({
      pathname: "/app/create-ticket"
    });
  }
  
  return (
    <>
    <div className="content-container">

      <div className="d-sm-flex justify-content-between tria-header-container" style={{padding: "0px 10px"}}>
        <h1 className="my-auto tria-header mobile-hidden">
          <a href="/app/devices">Devices</a>
          &nbsp; {assetTag}
        </h1>
        {!isLoading && !isError && 
          <AddMaintenanceWindowButton deviceId={deviceId} updateMaintenanceWindow={setCurrentMaintenanceWindow}/>}
      </div>

    {isLoading ?
      <div className="text-center"><i className="fas fa-spinner m-3 mt-4 fa-spin" style={{fontSize: "30px"}}></i> </div> :

    isError ?
      <div className="text-center roboto-15 my-4">
        <i className="fas fa-ban blue my-1"></i>
        <p className="my-1">TRiA did not identify Device data with this ID. Please contact us if this is an error.</p>
        <p className="text-action my-1" onClick={createNewTicket}>Report if this is an error</p>
        {/*<p>Or</p>
        <p className="text-action my-1">Take me back to all devices</p>*/}
      </div> :

      <> {/* Not loading / no error */}
      <GaugeList 
        nodeStatus={nodeDetail.properties.Status}
        diskInfo={diskInfo} 
        metrics={nodeDetail.metrics} 
        cpuAlertDetails={nodeDetail.properties.cpuAlertDetails} 
        memoryAlertDetails={nodeDetail.properties.memoryAlertDetails}
      />

      <div className="d-flex flex-wrap">
        <div className="col-sm-6 d-flex">
          <TriaCard title="Device Properties" className="w-100" footer={(!pageExpanded && isMobile) && (<ExpandTableButton toggle={toggleExpandPage}/>)} >
            <DeviceProperties data={{ ...nodeDetail.properties, ipAddresses: nodeDetail.ipAddresses }} />
          </TriaCard>
        </div>
        {(!isMobile || isMobile && pageExpanded) &&
          <div className="col-sm-6">
            {nodeDetail.properties.virtualMachine && 
              <TriaCard title="Virtual Machine Details">
                <VirtualMachineDetails data={nodeDetail.properties.virtualMachine}/>
              </TriaCard>}
            <TriaCard title="Maintenance Window">
              <MaintenanceWindowCard currentWindow={currentMaintenanceWindow} deviceId={deviceId}/>
            </TriaCard>
            {nodeDetail.properties?.vmHost?.children && 
              <TriaCard title="VMWare Children" bodyClassName="px-0" id="vmwareChildrenCard">
                <VMWareChildrenTable data={nodeDetail.properties?.vmHost?.children} setLoading={setIsLoading} setError={setError} setAssetTag={setAssetTag}/>
              </TriaCard>}
          </div>}
      </div>
      </>}

    </div>
    </>
  )
}

const getNodeMetricGaugeColor = (metric, threshold) => {
  if (threshold < 0) {
    threshold = 90;
  }
  const thresholdDifference = threshold - metric;

  if (thresholdDifference < 0) {
    return "red";
  } else if (thresholdDifference <= 5) {
    return "yellow";
  } else {
    return "blue"
  }

}

const getResponseTimePct = (responseTime) => {
  // Value shown as the "max" in Solarwinds gauges
  const maxResponseTime = 2500;

  return (responseTime / maxResponseTime) * 100
}

const getResponseTimeColor = (responseTime) => {
  if (responseTime >= 1500) {
    return "red";
  } else if (responseTime >= 750) {
    return "yellow";
  } else {
    return "blue";
  }
}

const getPacketLossColor = (packetLoss) => {
  return packetLoss == 100 ? "red" : "blue";
}

// Memoize becuase using 'stateAppActions' seems to remove fill color of Gauges

const ParseDiskName = data =>{

  if( data.DeviceId == null)
    return data.DisplayName.split(':')[0] + ':' || '';

  return data.DeviceId 
}

const GaugeList = React.memo(({ nodeStatus, diskInfo, metrics, cpuAlertDetails, memoryAlertDetails }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [diskIndex, setDiskIndex] = useState(0);
  const toggle  =()=> setDropdownOpen(prevState => !prevState);
  
  const toggleClick = (val) => {
    setDiskIndex(val)
    setDropdownOpen(prevState => !prevState);
  }
  let cols = 4;
  let MenuItem = <></>
  if(diskInfo != null) 
  {cols = 5

MenuItem =  <Dropdown className="align-self-start my-auto ml-auto cursor" isOpen={dropdownOpen} toggle={toggle}>
<DropdownToggle
  tag="span"
  data-toggle="dropdown"
  aria-expanded={dropdownOpen}
>
<i className="fas fa-ellipsis-h align-self-start my-auto ml-auto cursor"/>
</DropdownToggle>
<DropdownMenu>
  {diskInfo.map((e, ix) => {
    return <DropdownItem  disabled={ix==diskIndex} value={ix} onClick={(e) => toggleClick(ix)}>{ParseDiskName(e)}</DropdownItem>
  })}
</DropdownMenu>
</Dropdown>

  }
  return (
    <>
      <div className="d-flex justify-content-between overflow-x-scroll-lg">
        <div className={"gauge-col-wrapper " + (cols === 5 ? 'col-5-width' : '')}>
          <TriaCard title="Average CPU Load" id="avgCpuLoadCard" style={{margin: "10px"}}>
            {nodeStatus !== 1
              ? <GaugeErrorMessage message='Not Available'/>
              : <>
                {!metrics.CPULoad ?
                  <Gauge 
                    percentageFilled={0} value={0} unit={"%"} width={203} height={161}
                  /> :
                  metrics.CPULoad >= 0 ? 
                    <Gauge 
                      percentageFilled={metrics.CPULoad} value={metrics.CPULoad} unit={"%"} width={203} height={161} 
                      color={getNodeMetricGaugeColor(metrics.CPULoad, cpuAlertDetails.AlertCPUThreshold)}
                    /> : 
                    <GaugeErrorMessage
                      message='This node was not configured to report Average CPU'
                    />}
                </>
            }
          </TriaCard>
        </div>

        <div className={"gauge-col-wrapper " + (cols === 5 ? 'col-5-width' : '')}>
          <TriaCard title="Average Memory Used" id="avgMemUsedCard" style={{margin: "10px"}}>
            {nodeStatus !== 1
              ? <GaugeErrorMessage message='Not Available'/>
              : <>
                {!metrics.PercentMemoryUsed ?
                  <Gauge 
                    percentageFilled={0} value={0} unit={"%"} width={203} height={161}
                  /> :
                  metrics.PercentMemoryUsed >= 0 ? 
                    <Gauge 
                      percentageFilled={metrics.PercentMemoryUsed} value={metrics.PercentMemoryUsed} unit={"%"} width={203} height={161} 
                      color={getNodeMetricGaugeColor(metrics.PercentMemoryUsed, memoryAlertDetails.AlertRAMThreshold)}
                    /> : 
                    <GaugeErrorMessage
                      message='This node was not configured to report Average Memory Used'
                    />}
                </>
            }
          </TriaCard>
        </div>

        <div className={"gauge-col-wrapper " + (cols === 5 ? 'col-5-width' : '')}>
          <TriaCard title="Response Time" id="responseTimeCard" style={{margin: "10px"}}>
            {nodeStatus !== 1
              ? <GaugeErrorMessage message='Not Available'/>
              : <>
                {metrics.ResponseTime >= 0 ? 
                  <Gauge 
                    percentageFilled={getResponseTimePct(metrics.ResponseTime)} 
                    value={metrics.ResponseTime} unit={"MS"} width={203} height={161} 
                    color={getResponseTimeColor(metrics.ResponseTime)}
                  /> :
                  <GaugeErrorMessage
                    message='This node was not configured to report Response Time'
                  />}
                </>
            }
          </TriaCard>
        </div>

        <div className={"gauge-col-wrapper " + (cols === 5 ? 'col-5-width' : '')}>
          <TriaCard title="Packet Loss" id="packetLossCard" style={{margin: "10px"}}>
            {nodeStatus !== 1
              ? <GaugeErrorMessage message='Not Available'/>
              : <>
                {metrics.PercentLoss >= 0 ? 
                  <Gauge 
                    percentageFilled={metrics.PercentLoss} value={metrics.PercentLoss} unit={"%"} width={203} height={161} 
                    color={getPacketLossColor(metrics.PercentLoss)}
                  /> :
                  <GaugeErrorMessage
                    message='This node was not configured to report Packet Loss'
                  />}
                </>
            }
          </TriaCard>
        </div>

        {cols === 5 ? <div className={"gauge-col-wrapper " + (cols === 5 ? 'col-5-width ' : ' ') + (cols === 4 ? 'd-none ' : ' ')}>
          <TriaCard title={"Disk Used " + ParseDiskName(diskInfo[diskIndex])} menuItem={MenuItem}  id="diskUsed" style={{margin: "10px"}}>
            {nodeStatus !== 1
              ? <GaugeErrorMessage message='Not Available'/>
              : <>
                {diskInfo?.length == 0  ?
                <Gauge 
                    percentageFilled={0} value={0} unit={"%"} width={203} height={161}
                  /> :
                diskInfo?.length > 0 ? 
                  <Gauge 
                    percentageFilled={Math.round(diskInfo[diskIndex]?.VolumePercentUsed)} value={Math.round(diskInfo[diskIndex]?.VolumePercentUsed)} unit={"%"} width={203} height={161} 
                    color={getNodeMetricGaugeColor(Math.round(diskInfo[diskIndex].VolumePercentUsed), cpuAlertDetails.AlertCPUThreshold)}
                  /> : 
                  <GaugeErrorMessage
                    message='This node was not configured to report disk info'
                  />}
                </>
            }
          </TriaCard>
        </div>
 : <></>}
      </div>
      
    </>
  )
})

const GaugeErrorMessage = ({ message }) => {
  return (
    <div className="text-center pt-4" style={{minWidth: "200px", height: "160px"}}>
      <p className="tria-input-font">{message}</p>
      <i className="fas fa-ban blue"></i>
    </div>
  )
}

const DeviceProperties = props => {
  const status = props.data.Status;
  const { information, pollingDetails, cpuAlertDetails, memoryAlertDetails, ipAddresses } = props.data;

  const nodeStatus = nodeStatuses.find(nodeStatus => nodeStatus.StatusId == status);

  return (
    <>
    <div className="d-flex justify-content-between card-content" id="devicePropertiesCard">
      <p className="sub-header my-auto">Node Status is {nodeStatus?.ShortDescription}</p>
      <div className="">
        {nodeStatus?.Color && <StatusIconToken color={nodeStatus?.Color}/>}
      </div>
    </div>
    <div className="card-content">
      <p className="card-sub-header">Device Information</p>
        <CardFieldValue field="Assettag" value={information.AssetTag} id="dp-assettag"/>
        <CardFieldValue field="Datacenter" value={information.Datacenter == "n/a" ? "" : information.Datacenter} id="dp-datacenter"/>
        <CardFieldValue field="Vendor" value={information.Vendor} id="dp-vendor"/>
        <CardFieldValue field="Machine Type" value={information.MachineType} id="dp-machine-type"/>
        <CardFieldValue field="System Name" value={information.SysName} id="dp-system-name"/>
        <CardFieldValue field="Version" value={information.IOSVersion} id="dp-version"/>
        <CardFieldValue field="DNS Name" value={information.DNS} id="dp-dns-name"/>
        <CardFieldValue 
          field="Last Boot"
          value={information.LastBoot ? 
            formatSolarwindsApiDate(information.LastBoot, "M/D/YYYY h:mm A") : ""} 
          id="dp-last-boot"
        />
        <CardFieldValue field="Sysobject ID" value={information.SysObjectID} id="dp-sysobject-id"/>
        <CardFieldValue field="Description" value={information.NodeDescription} id="dp-description"/>
      <p className="card-sub-header">Environment Tags</p>
        <CardFieldValue field={"Primary"} value={information.Application_Environment} id="dp-primary"/>
        <CardFieldValue field={"Secondary"} value={information.Sub_Application_Environment} id="dp-secondary"/>
        <CardFieldValue field={"Tertiary"} value={information.Sub_Sub_Application_Environment} id="dp-tertiary"/>
      <p className="card-sub-header">Polling Details</p>
        <CardFieldValue
          field={"IP Address"}
          value={ipAddresses?.length > 0 ? ipAddresses.map(addr => (<span key={addr}>{addr}<br/></span>)) : pollingDetails.IP_Address || ""}
          id="dp-ip-address"
        />
        <CardFieldValue field={"Ping Only"} value={(information.ObjectSubType.toLowerCase() == "icmp").toString().toUpperCase()} id="dp-ping-only"/>
        <CardFieldValue 
          field={"Next Poll"}
          value={pollingDetails?.NextPoll ? 
            formatSolarwindsApiDate(pollingDetails.NextPoll, "M/D/YYYY h:mm A") : ""} 
          id="dp-next-poll"
        />
        <CardFieldValue 
          field={"Last DB Update"} 
          value={pollingDetails?.LastSync ?
            formatSolarwindsApiDate(pollingDetails.LastSync, "M/D/YYYY h:mm A") : ""} 
          id="dp-last-db-update"
        />
      <p className="card-sub-header">CPU Alert Details</p>
        <CardFieldValue field={"Threshold"} value={cpuAlertDetails.AlertCPUThreshold < 1 ? "90%" : cpuAlertDetails.AlertCPUThreshold + "%"} id="dp-cpu-threshold"/>
        <CardFieldValue field={"Suppressed"} value={cpuAlertDetails.AlertCPUSuppression?.toString()} valueClassName={"text-capitalize"} id="dp-cpu-surpressed"/>
        <CardFieldValue field={"Custom Alert"} value={cpuAlertDetails.AlertCPUCustom?.toString()} valueClassName={"text-capitalize"} id="dp-cpu-custom-alert"/>
        <CardFieldValue field={"Assoc. Ticket"} value={cpuAlertDetails.AlertCPUPortalTicket || ""} id="dp-cpu-assoc-ticket"/>
      <p className="card-sub-header">Memory Alert Details</p>
        <CardFieldValue field={"Threshold"} value={memoryAlertDetails.AlertRAMThreshold < 1 ? "90%" : memoryAlertDetails.AlertRAMThreshold + "%"} id="dp-mem-threshold"/>
        <CardFieldValue field={"Suppressed"} value={memoryAlertDetails.AlertRAMSuppression?.toString()} valueClassName={"text-capitalize"} id="dp-mem-description"/>
        <CardFieldValue field={"Custom Alert"} value={memoryAlertDetails.AlertRAMCustom?.toString()} valueClassName={"text-capitalize"} id="dp-mem-custom-alert"/>
        <CardFieldValue field={"Assoc. Ticket"} value={memoryAlertDetails.AlertRAMPortalTicket || ""} id="dp-mem-assoc-ticket"/>
    </div>
    </>
  )
}

const StatusIconToken = props => {

  const getIcon = (color) => {
    switch (color) {
      case "green":
        return "fas fa-check"
      case "yellow":
        return "fas fa-exclamation-triangle"
      case "red":
        return "fas fa-exclamation"
      case "blue":
        return "fas fa-ban"
      default:
        return "fas fa-question"
    }
  }

  return (
    <div className="status-icon-token" color={props.color || "gray"}>
      <i className={getIcon(props.color || "gray")}></i>
    </div>
  )
}

const VirtualMachineDetails = ({data}) => {
  return (
    <>
    <div className="card-content" id="virtualMachineDetailsCard">
      <CardFieldValue field="ESX Host" value={data.HostName} id={"vmd-esx-host"}/>
      <CardFieldValue field="Operational State" 
        value={
          <>
          <i className="fas fa-circle status-icon my-auto px-2" color={VirtualMachineGuestStateIconColor[data.GuestState]}/> 
          {VirtualMachineGuestStateMapping[data.GuestState]}
          </>}
        id={"vmd-operational-state"}
      />
      <CardFieldValue field="Guest Status" 
        value={
          <>
          <i className="fas fa-circle status-icon my-auto px-2" color={nodeStatuses[data.ManagedStatus].Color}/> 
          {nodeStatuses[data.ManagedStatus].ShortDescription}
          </>}
        id={"vmd-guest-status"}
      />
      {/*<CardFieldValue field="Network Usage" value={"?"} id={"vmd-network-usage"}/>
      <CardFieldValue field="Host CPU Usage" value={"?"} id={"vmd-host-cpu-usage"}/>
      <CardFieldValue field="Host Memory Usage" value={"?"} id={"vmd-host-memory-usage"}/>
      <CardFieldValue field="Host Network Usage" value={"?"} id={"vmd-host-network-usage"}/>*/}
      <CardFieldValue 
        field={"VMware tools version & status"} 
        value={data.GuestVmWareToolsVersion + " | " + (VmWareToolsStatusMapping[data.GuestVmWareToolsStatus] || "")}
        id={"vmd-tools-version"}
      />
      <CardFieldValue field="Memory Configured" value={data.MemoryConfigured ? (data.MemoryConfigured / (1024**3)).toFixed(1) + " GB" : ""} id={"vmd-mem-config"}/>
      <CardFieldValue field="Boot Time" 
        value={data.BootTime ? 
          formatSolarwindsApiDate(data.BootTime, "M/D/YYYY h:mm A") : ""}
        id={"vmd-boot-time"}
      />
    </div>
    </>
  )
}

const VmWareToolsStatusMapping = {
  toolsOk: "Tools are ok",
  toolsOld: "Tools are old",
  toolsNotRunning: "Tools are not running",
  toolsNotInstalled: "Tools are not installed"
}

const VirtualMachineGuestStateMapping = {
  unknown: "Unknown",
  notRunning: "Not running",
  shuttingDown: "Shutting down",
  running: "Running"
}

const VirtualMachineGuestStateIconColor = {
  unknown: "gray",
  notRunning: "red",
  shuttingDown: "blue",
  running: "green"
}

const MaintenanceWindowCard = ({currentWindow, deviceId}) => {
  const [isWaiting, setWaiting] = useState(false);
  const [hasWindow, setHasWindow] = useState(true);
  const [isError, setError] = useState(false);
  const [stateApp, stateAppActions] = appState();

  useEffect(() => {
    if (currentWindow.UnManaged == null) {
      setHasWindow(false);
    } else {
      const startTime = moment(parseSolarwindsApiDate(currentWindow.UnManageFrom));
      const endTime = moment(parseSolarwindsApiDate(currentWindow.UnManageUntil));

      if (endTime < startTime || endTime < moment()) {
        setHasWindow(false);
      } else {
        setHasWindow(true);
      }
    }
    
  }, [currentWindow])


  const deleteDeviceMaintenanceWindow = async (deviceId) => {
    if (isWaiting) {
      return
    }

    setWaiting(true);
    let alertMessage = `Successfully deleted maintenance window for device #${deviceId}`;
    let alertColor = "success";
    try {
      let response = await SolarwindService.delete_device_maintenance_window(deviceId);
      setHasWindow(false);
    } catch (e) {
      console.log(e);
      setError(true);
      alertMessage = `Error while deleting maintenance window for device #${deviceId}`;
      alertColor = "danger";

    }
    setWaiting(false);

    stateAppActions.setAlert({
      content: <p style={{color:"white"}}>{alertMessage}</p>,
      className: alertColor + " tria-alert",
      visible: true
    });
    setTimeout(() => {
      stateAppActions.setAlert(prevState => ({...prevState, visible: false }))
    }, 3000);

  }

  return (
    <>
    {hasWindow ?
    <div className="mw-card-content" id="maintenanceWindowCard">
      <div className="d-flex justify-content-between mb-3">
        <div>
          <p className="sub-header mb-0">Start Date</p>
          <p className="helvetica-font">
            {currentWindow.UnManageFrom ? formatSolarwindsApiDate(currentWindow.UnManageFrom, "MM/DD/YYYY") : ""}
          </p>    
        </div>
        <div className="text-right">
          <p className="sub-header mb-0">End Date</p>
          <p className="helvetica-font">
            {currentWindow.UnManageUntil ? formatSolarwindsApiDate(currentWindow.UnManageUntil, "MM/DD/YYYY") : ""}
            </p>
        </div>
      </div>
      <div className="d-flex justify-content-between mb-2">
        <div>
          <p className="sub-header mb-0">Start Time</p>
          <p className="helvetica-font">
            {currentWindow.UnManageFrom ? formatSolarwindsApiDate(currentWindow.UnManageFrom, "h:mmA") : ""}
          </p>    
        </div>
        <div className="text-right">
          <p className="sub-header mb-0">End Time</p>
          <p className="helvetica-font">
            {currentWindow.UnManageUntil ? formatSolarwindsApiDate(currentWindow.UnManageUntil, "h:mmA") : ""}
          </p>
        </div>
      </div>
      <div className="text-right">
        <p className="d-inline-block text-action" onClick={() => deleteDeviceMaintenanceWindow(deviceId)}>
          {isWaiting && <i className="fas fa-spinner fa-spin mr-1"/>}
          Delete
        </p>
      </div>
    </div> :

    <div className="pb-3">
      <div className="row justify-content-center mt-3 mb-3">
        <p className="tria-input-font" style={{fontWeight: "500"}}>No Maintenance Window Set</p>
      </div>
      <div className="row justify-content-center mb-3">
        <i className="fas fa-ban blue my-auto px-2"></i>
      </div>
      {/*<div className="row justify-content-center">
        <Button className="gray-button">Set Maintenance Window</Button>
      </div>*/}
    </div>
    }
    </>
  )
}


const VMWareChildrenTable = props => {
  const [numberShown, setNumberShown] = useState(5);
  const [sortOrder, setSortOrder] = useState(true); // false = descending, true = ascending
  const pageSize = 5

  const expandTable = (e) => { setNumberShown(numberShown + pageSize) }
  const toggleSortOrder = (e) => {
    e.preventDefault();
    setSortOrder(!sortOrder);
  }
  const sortAscending = (a,b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0);
  const sortDescending = (a,b) => (a.Name > b.Name) ? -1 : ((b.Name > a.Name) ? 1 : 0);
  
  return (
    <>
    <table className="table tria-table" id="vmwareChildrenTable">
      <thead>
        <tr>
          <th className="cursor" style={{paddingLeft: "20px"}} onClick={toggleSortOrder}>
            Name and ID
            {" "}<i className={"fas fa-chevron-" + (sortOrder ? "up" : "down")} style={{color: "#2E5BFF"}}/>
          </th>
          <th className="tablet-hidden" style={{width: "160px"}}>Operating System</th>
          <th className="mobile-hidden text-center">State</th>
          <th>IP Address</th>
        </tr>
      </thead>
      <tbody>
        {props.data
          .sort(sortOrder ? sortAscending : sortDescending)
          .slice(0, numberShown)
          .map((child, i) => <VMChildRow data={child} i={i} setLoading={props.setLoading} setError={props.setError} setAssetTag={props.setAssetTag}/>)}
      </tbody>
    </table>
    {props.data.length > numberShown && <ExpandTableButton toggle={expandTable}/>}
    </>
  )
}

const VMChildRow = props => {
  const source = axios.CancelToken.source();
  const history = useHistory();
  const nodeStatus = nodeStatuses.find(nodeStatus => nodeStatus.StatusId == props.data.Status);

  const goToChildPage = async (e, i) => {
    // Portal device ID is not included in child data. Currently need to go to portal to get it first
    try {
      props.setAssetTag(props.data.Name);
      props.setLoading(true);
      let responseData = await ConnectriaTicketsService.getPortalDevice(null, {assetTag: props.data.Name});
      if (Object.entries(responseData).length == 0) {
        throw ('No portal device with that name found')
      }
      let deviceId = responseData.devices[0].id
      history.push(`/app/devices/${deviceId}`, {assetTag: props.data.Name});
    } catch (e) {
      console.log(e)
      props.setError(true);
      props.setAssetTag("");
      props.setIsLoading(false);
    }
  }

  const getIcon = (color) => {
    switch (color) {
      case "blue":
        return "fas fa-ban"
      default:
        return "fas fa-circle"
    }
  }

  return (
    <tr>
      <td style={{paddingLeft: "20px"}} onClick={goToChildPage}>
        <div className="break-word d-inline-block cursor" style={{color: "black", fontWeight: "400"}}>
          <i className={"my-auto px-2 status-icon " + getIcon(nodeStatus.Color)} color={nodeStatus.Color}></i>
          {props.data.Name}
        </div>
      </td>
      <td className="tablet-hidden">{props.data.GuestName}</td>
      <td className="mobile-hidden text-center">
        <i id={"vmchild" + props.i} className={"fas fa-power-off " + (props.data.PowerState == "poweredOn" ? "blue" : "gray")}/>
        <UncontrolledTooltip placement="right" target={"vmchild" + props.i}>
          This VM is powered {props.data.PowerState == "poweredOn" ? "ON" : "OFF"}
        </UncontrolledTooltip>
      </td>
      <td>{props.data.IpAddress == "n/a" ? "" : props.data.IpAddress}</td>
    </tr>
  )
}

const ExpandTableButton = props => {
  return (
    <div className="expand-table-btn cursor" onClick={props.toggle}>
      EXPAND TABLE
    </div>
  )
}

const formatSolarwindsApiDate = (solarwindsDate, format) => {
  const milliseconds = parseSolarwindsApiDate(solarwindsDate);
  return formatMillisecondsToDateTime(milliseconds, format);
}

const parseSolarwindsApiDate = (solarwindsDate) => {
  // Solarwinds API Datetime example: "/Date(1604344612497)/"

  // Return milliseconds in between parentheses
  var milliseconds = solarwindsDate.match(/\(([^)]+)\)/)[1];
  return parseInt(milliseconds);
}

const formatMillisecondsToDateTime = (milliseconds, format) => {
  // Example format: 10/5/2020 12:21:53 AM
  return moment(milliseconds).format(format);
}

const AddMaintenanceWindowButton = props => {
  const [isOpen, setOpen] = useState(false);
  const [stateApp, stateAppActions] = appState();

  useEffect(() => {
    if (isMobile || isTablet) {
      stateAppActions.setContentBlade({
        title: "SET MAINTENTANCE WINDOW" || "",
        visible: isOpen,
        content: <MaintenanceWindowForm {...props}/>
      })

    }
  }, [isOpen])

  useEffect(() => {
    setOpen(stateApp.contentBlade.visible)

  }, [stateApp.contentBlade.visible])

  return (
    <>
    {(!isMobile && !isTablet) && 
      <ButtonDropdown label="Maintenance Window" title={"SET MAINTENTANCE WINDOW"} isOpen={isOpen} setIsOpen={setOpen} iconPos="left">
        <MaintenanceWindowForm {...props}/> 
      </ButtonDropdown>}
    {(isMobile || isTablet) && 
      <button 
        className="tria-btn w-sm-100 mr-1"
        onClick={() => setOpen(true)}
      >
        <i className="fa fa-plus"/>
        Maintenance Window 
      </button>}
    </>
  )
}

const MaintenanceWindowForm = props => {

  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [isWaiting, setWaiting] = useState(false);
  const [isError, setError] = useState(false);
  const [stateApp, stateAppActions] = appState();
  const [openCalendar, setOpenCalendar] = useState({
    start: false,
    end: false
  });


  // Refresh date fields when closing/re-opening
  useEffect(() => {
    setStartDate(new Date());
    setStartTime(new Date());
    setEndDate(null);
    setEndTime(null);
  }, [props.isOpen])

  const addMaintenanceWindow = async () => {
    // UTC Format 2020-11-01 12:00 -0600

    let startDateStr = moment(startDate).format("MM/DD/YYYY");
    let startTimeStr = moment(startTime).format("HH:mm");
    let endDateStr = moment(endDate).format("MM/DD/YYYY");
    let endTimeStr = moment(endTime).format("HH:mm");
    
    let startTimeUTC = moment(startDateStr + " " + startTimeStr, "MM/DD/YYYY HH:mm").format("YYYY-MM-DD HH:mm ZZ");
    let endTimeUTC = moment(endDateStr + " " + endTimeStr, "MM/DD/YYYY HH:mm").format("YYYY-MM-DD HH:mm ZZ");
    console.log('startTimeUTC: ', startTimeUTC);
    console.log('endTimeUTC: ', endTimeUTC);

    let payload = {
      windowType: "date",
      startTimeUTC: startTimeUTC,
      endTimeUTC: endTimeUTC,
      reason: "Patching Window",
    }

    setWaiting(true);

    let alertMessage = `Successfully set maintenance window for device #${props.deviceId}`;
    let alertColor = "success";
    try {
      
      let createResponse = await SolarwindService.create_device_maintenance_window(props.deviceId, payload);
      if (createResponse.data.status != "ok") {
        throw "Create maintenance window - status not 'ok'"
      }

      let updatedDetailResponse = await SolarwindService.device_detail(props.deviceId);

      props.updateMaintenanceWindow({...updatedDetailResponse.data.properties.currentMaintenanceWindow});

      setStartDate(new Date());
      setStartTime(new Date());
      setEndDate(null);
      setEndTime(null);

    } catch (e) {
      console.log(e);
      setError(true);
      alertMessage = `Error while setting maintenance window for device #${props.deviceId}`;
      alertColor = "danger";
    }
    setWaiting(false);

    stateAppActions.setAlert({
      content: <p style={{color:"white"}}>{alertMessage}</p>,
      color: alertColor + " tria-alert",
      visible: true
    });
    setTimeout(() => {
      stateAppActions.setAlert(prevState => ({...prevState, visible: false }))
    }, 5000);

  }

  const isInvalidInput = () => {
    if ((startDate == "Invalid date" || startDate == null) ||
      (startTime == "Invalid date" || startTime == null) || 
      (endDate == "Invalid date" || endDate == null) || 
      (endTime == "Invalid date" || endTime == null)) {
        return true;
      }

    // Turn start and end dates/times to strings and put corresponding values together. Then convert into moment object to compare them
    const startDateTime = moment(moment(startDate).format("MM/DD/YYYY") + " " + moment(startTime).format("HH:mm"), "MM/DD/YYYY HH:mm");
    const endDateTime = moment(moment(endDate).format("MM/DD/YYYY") + " " + moment(endTime).format("HH:mm"), "MM/DD/YYYY HH:mm");
    // Invalid input if the start date comes after the end date
    if (startDateTime >= endDateTime) {
      return true;
    }

    return false;
  }

  return (
    <>
    <p className="sub-header py-2">Only one maintenance window can be set at a time</p>

      {/* Might use in future implementation*/}
      {/*<div className="d-flex justify-content-between py-2">
        <p className="label-font" style={{marginRight: "5px"}}>Mute alerts on this object</p>
        <ToggleSwitch/>
      </div>*/}

      <div className="py-2">
        <p className="sub-header">Start of Maintenance:</p>
        <div className="row" style={{height: "40px"}}>
          <DateTimePicker
            id="startDatePicker"
            date={true} time={false} placeholder={""}
            format={"MM/DD/YYYY"}
            defaultValue={new Date()} value={startDate}
            min={new Date()}
            className="col-6 mw-rw-datetime-picker" containerClassName={"tria-input input-font rw-dropdown-font rw-container rw-no-btn"}
            onChange={ (value) => { setStartDate(value)} }
            onFocus={ () => setOpenCalendar({...setOpenCalendar, start: "date"}) }
            defaultOpen={false}
            onBlur={ () => setOpenCalendar({...setOpenCalendar, start: false}) }
            open={openCalendar.start}
          />
          <DateTimePicker
            id="startTimePicker"
            date={false} placeholder={""}
            defaultValue={new Date()} value={startTime}
            className="col-6 mw-rw-datetime-picker" containerClassName={"rw-dropdown-font rw-container"}
            timeIcon={<i className="fas fa-chevron-down" style={{color: "#BFC5D2"}}/>}
            onChange={(value) => { setStartTime(value) }}
          />
        </div>
      </div>

      <div className="py-2">
        <p className="sub-header">End of Maintenance:</p>
        <div className="row" style={{height: "40px"}}>
          {/*<span className="col-6">
          <Input type="text" className="input-font tria-input" style={{height: "40px"}}/>
        </span>*/}
          <DateTimePicker
            id="endDatePicker" value={endDate}
            date={true} time={false} placeholder={""}
            min={new Date()}
            format={"MM/DD/YYYY"}
            className="col-6 mw-rw-datetime-picker" containerClassName={"tria-input input-font rw-dropdown-font rw-container rw-no-btn"}
            onChange={ (value) => { setEndDate(value)} }
            onFocus={ () => setOpenCalendar({...setOpenCalendar, end: "date"}) }
            defaultOpen={false}
            onBlur={ () => setOpenCalendar({...setOpenCalendar, end: false}) }
            open={openCalendar.end}
          />
          <DateTimePicker
            id="endTimePicker" value={endTime}
            date={false} placeholder={""}
            className="col-6 mw-rw-datetime-picker" containerClassName={"rw-dropdown-font rw-container"}
            timeIcon={<i className="fas fa-chevron-down" style={{color: "#BFC5D2"}}/>}
            onChange={ (value) => { setEndTime(value)} }
          />
        </div>
      </div>

      <div className="d-flex mt-2">
        <Button 
          className="encode-sans-15 tria-btn-blue ml-auto" size="sm" 
          style={{minWidth: "85px", color: "white"}}
          onClick={addMaintenanceWindow} disabled={isInvalidInput() || isWaiting}>
            {isWaiting ? <i className="fas fa-spinner fa-spin" style={{color: "white"}}/> : "Schedule"}
        </Button>
        </div>
    </>
  )
}

const Gauge = props => {
  const numTicks = 35;
  // Create 35 ticks in the graph. The "value" key is used for the "dataKey" below
  const mydata = [...new Array(numTicks)].map(_ => { return {value: 1} })

  const numTicksFilled = Math.round(((props.percentageFilled / 100) || 0) * numTicks)

  const getOuterTicksColor = (colorName) => {
    switch (colorName) {
      case "yellow":
        return "#F0E65B";
      case "red":
        return "#D63649";
      default:
        return "#2E5BFF"
    }
  } 

  return (
    <div className="d-flex justify-content-center">
    <PieChart width={props.width} height={props.height}>
      <Pie
        className="gauge-inner-dots"
        data={mydata} 
        dataKey={"value"}
        cx={98} 
        cy={90} 
        startAngle={180}
        endAngle={0}
        innerRadius={56}
        outerRadius={59}
        paddingAngle={3.5}
        startAngle={190}
        endAngle={-10}
        fill={"#E0E7FF"}
      >
      </Pie>
      <Pie
        className="gauge-outer-ticks"
        data={mydata} 
        dataKey={"value"}
        cx={98} 
        cy={90} 
        startAngle={180}
        endAngle={0}
        innerRadius={65}
        outerRadius={90}
        paddingAngle={3.25}
        startAngle={190}
        endAngle={-10}
      >
      <Label value={props.value || "0"} position="centerBottom"/>
      <Label value={props.unit || ""} position="centerTop"/>
      {/* If a tick should be filled, give it the default blue color, unless a fill color is specified.
          If the tick is past the threshold then color it gray */}
      {mydata.map((_, i) => <Cell fill={i < numTicksFilled ? (getOuterTicksColor(props.color)) : "#E0E7FF"} />)}
      </Pie>
      
    </PieChart>
    </div>
  );
}

const PollingDetails = props => {
  const { information, pollingDetails, ipAddresses } = props.data;
  return (
    <div className="card-content">
      <CardFieldValue
        field={"IP Address"}
        value={ipAddresses?.length > 0 ? ipAddresses.map(addr => (<span key={addr}>{addr}<br/></span>)) : pollingDetails.IP_Address || ""}
        id="dp-ip-address"
      />
      <CardFieldValue field={"Ping Only"} value={(information.ObjectSubType.toLowerCase() == "icmp").toString().toUpperCase()} id="dp-ping-only"/>
      <CardFieldValue 
        field={"Next Poll"}
        value={pollingDetails?.NextPoll ? 
          formatSolarwindsApiDate(pollingDetails.NextPoll, "M/D/YYYY h:mm A") : ""} 
        id="dp-next-poll"
      />
      <CardFieldValue 
        field={"Last DB Update"} 
        value={pollingDetails?.LastSync ?
          formatSolarwindsApiDate(pollingDetails.LastSync, "M/D/YYYY h:mm A") : ""} 
        id="dp-last-db-update"
      />
    </div>
  )
}

const EnvironmentTags = props => {
  const {information} = props.data;
  return (
    <div className="card-content">
      <CardFieldValue field={"Primary"} value={information.Application_Environment} id="dp-primary"/>
      <CardFieldValue field={"Secondary"} value={information.Sub_Application_Environment} id="dp-secondary"/>
      <CardFieldValue field={"Tertiary"} value={information.Sub_Sub_Application_Environment} id="dp-tertiary"/>
    </div>
  )
}

const CustomerDescription = ({ data, operatingSystem, serviceTag }) => {
  const {information, pollingDetails, cpuAlertDetails, memoryAlertDetails} = data;
  const status = data.Status;
  const nodeStatus = nodeStatuses.find(nodeStatus => nodeStatus.StatusId == status);

  return (
    <div className="card-content">
      <div className="d-flex justify-content-between mb-2" id="devicePropertiesCard">
        <p className="my-auto">Node Status is {nodeStatus?.ShortDescription}</p>
        <div className="">
          {nodeStatus?.Color && <StatusIconToken color={nodeStatus?.Color}/>}
        </div>
      </div>
      <CardFieldValue field="Assettag" value={information.AssetTag} id="dp-assettag"/>
      <CardFieldValue field="Servicetag" value={serviceTag} id="dp-servicetag"/>
      <CardFieldValue field="OS" value={operatingSystem} id="dp-operatingsystem"/>
      <CardFieldValue 
        field="Last Boot"
        value={information.LastBoot ? 
          formatSolarwindsApiDate(information.LastBoot, "M/D/YYYY h:mm A") : ""} 
        id="dp-last-boot"
      />
      <CardFieldValue field="Description" value={information.NodeDescription} id="dp-description"/>
      <CardFieldValue field="DNS Name" value={information.DNS} id="dp-dns-name"/>
    </div>
  )
}



const GaugeGrid = React.memo(({diskInfo, metrics, cpuAlertDetails, memoryAlertDetails}) => {
  return (
    <>
        <div className="d-flex justify-content-center">
          <TriaCard title="Average CPU Load" id="avgCpuLoadCard" style={{margin: "10px"}}>
            {!metrics.CPULoad ?
             <Gauge 
                percentageFilled={0} value={0} unit={"%"} width={203} height={161}
              /> :
            metrics.CPULoad >= 0 ? 
              <Gauge 
                percentageFilled={metrics.CPULoad} value={metrics.CPULoad} unit={"%"} width={203} height={161} 
                color={getNodeMetricGaugeColor(metrics.CPULoad, cpuAlertDetails.AlertCPUThreshold)}
              /> : 
              <div className="text-center pt-4" style={{minWidth: "200px", height: "160px"}}>
                <p className="tria-input-font">This node was not configured to report Average CPU</p>
                <i className="fas fa-ban blue"></i>
              </div>}
          </TriaCard>

          <TriaCard title="Average Memory Used" id="avgMemUsedCard" style={{margin: "10px"}}>
            {!metrics.PercentMemoryUsed ?
            <Gauge 
              percentageFilled={0} value={0} unit={"%"} width={203} height={161}
            /> :
              metrics.PercentMemoryUsed >= 0 ? 
              <Gauge 
                percentageFilled={metrics.PercentMemoryUsed} value={metrics.PercentMemoryUsed} unit={"%"} width={203} height={161} 
                color={getNodeMetricGaugeColor(metrics.PercentMemoryUsed, memoryAlertDetails.AlertRAMThreshold)}
              /> : 
              <div className="text-center pt-4" style={{minWidth: "200px", height: "160px"}}>
                <p className="tria-input-font">This node was not configured to report Average Memory Used</p>
                <i className="fas fa-ban blue"></i>
              </div>}
          </TriaCard>
        </div>

        <div className="d-flex justify-content-center">
          <TriaCard title="Response Time" id="responseTimeCard" style={{margin: "10px"}}>
            {metrics.ResponseTime >= 0 ? 
            <Gauge 
              percentageFilled={getResponseTimePct(metrics.ResponseTime)} 
              value={metrics.ResponseTime} unit={"MS"} width={203} height={161} 
              color={getResponseTimeColor(metrics.ResponseTime)}
            /> :
            <div className="text-center pt-4" style={{minWidth: "200px", height: "160px"}}>
              <p className="tria-input-font">This node was not configured to report Response Time</p>
              <i className="fas fa-ban blue"></i>
            </div>}
          </TriaCard>

          <TriaCard title="Packet Loss" id="packetLossCard" style={{margin: "10px"}}>
            {metrics.PercentLoss >= 0 ? 
            <Gauge 
              percentageFilled={metrics.PercentLoss} value={metrics.PercentLoss} unit={"%"} width={203} height={161} 
              color={getPacketLossColor(metrics.PercentLoss)}
            /> :
            <div className="text-center pt-4" style={{minWidth: "200px", height: "160px"}}>
              <p className="tria-input-font">This node was not configured to report Packet Loss</p>
              <i className="fas fa-ban blue"></i>
            </div>}
          </TriaCard>
        </div>
    </>
  )
})

export { 
  DeviceDetailPage, 
  AddMaintenanceWindowButton,
  CustomerDescription,
  EnvironmentTags,
  GaugeGrid, 
  MaintenanceWindowCard, 
  PollingDetails,
  VirtualMachineDetails
}


const nodeStatuses = [
  {
    "StatusId": 0,
    "StatusName": "Unknown",
    "ShortDescription": "Unknown",
    "Color": "gray"
  },
  {
    "StatusId": 1,
    "StatusName": "Up",
    "ShortDescription": "Up",
    "Color": "green"
  },
  {
    "StatusId": 2,
    "StatusName": "Down",
    "ShortDescription": "Down",
    "Color": "red"
  },
  {
    "StatusId": 3,
    "StatusName": "Warning",
    "ShortDescription": "Warning",
    "Color": "yellow"
  },
  {
    "StatusId": 4,
    "StatusName": "Shutdown",
    "ShortDescription": "Shutdown",
    "Color": "gray"
  },
  {
    "StatusId": 5,
    "StatusName": "Testing",
    "ShortDescription": "Testing",
    "Color": "gray"
  },
  {
    "StatusId": 6,
    "StatusName": "Dormant",
    "ShortDescription": "Dormant",
    "Color": "gray"
  },
  {
    "StatusId": 7,
    "StatusName": "NotPresent",
    "ShortDescription": "Not Present",
    "Color": "gray"
  },
  {
    "StatusId": 8,
    "StatusName": "LowerLayerDown",
    "ShortDescription": "Lower Layer Down",
    "Color": "red"
  },
  {
    "StatusId": 9,
    "StatusName": "Unmanaged",
    "ShortDescription": "Unmanaged",
    "Color": "blue"
  },
  {
    "StatusId": 10,
    "StatusName": "Unplugged",
    "ShortDescription": "Unplugged",
    "Color": "blue"
  },
  {
    "StatusId": 11,
    "StatusName": "External",
    "ShortDescription": "External",
    "Color": "purple"
  },
  {
    "StatusId": 12,
    "StatusName": "Unreachable",
    "ShortDescription": "Unreachable",
    "Color": "gray"
  },
  {
    "StatusId": 14,
    "StatusName": "Critical",
    "ShortDescription": "Critical",
    "Color": "red"
  },
  {
    "StatusId": 15,
    "StatusName": "PartlyAvailable",
    "ShortDescription": "Partly Available",
    "Color": "yellow"
  },
  {
    "StatusId": 16,
    "StatusName": "Misconfigured",
    "ShortDescription": "Misconfigured",
    "Color": "gray"
  },
  {
    "StatusId": 17,
    "StatusName": "Undefined",
    "ShortDescription": "Could Not Poll",
    "Color": "gray"
  },
  {
    "StatusId": 19,
    "StatusName": "Unconfirmed",
    "ShortDescription": "Unconfirmed",
    "Color": "yellow"
  },
  {
    "StatusId": 22,
    "StatusName": "Active",
    "ShortDescription": "Active",
    "Color": "green"
  },
  {
    "StatusId": 24,
    "StatusName": "Inactive",
    "ShortDescription": "Inactive",
    "Color": "gray"
  },
  {
    "StatusId": 25,
    "StatusName": "Expired",
    "ShortDescription": "Expired",
    "Color": "gray"
  },
  {
    "StatusId": 26,
    "StatusName": "MonitoringDisabled",
    "ShortDescription": "Monitoring Disabled",
    "Color": "gray"
  },
  {
    "StatusId": 27,
    "StatusName": "Disabled",
    "ShortDescription": "Disabled",
    "Color": "gray"
  },
  {
    "StatusId": 28,
    "StatusName": "NotLicensed",
    "ShortDescription": "Not Licensed",
    "Color": "gray"
  },
  {
    "StatusId": 29,
    "StatusName": "OtherCategory",
    "ShortDescription": "Other",
    "Color": "gray"
  },
  {
    "StatusId": 30,
    "StatusName": "NotRunning",
    "ShortDescription": "Not Running",
    "Color": "blue"
  },
  {
    "StatusId": 31,
    "StatusName": "Online",
    "ShortDescription": "Online",
    "Color": "green"
  },
  {
    "StatusId": 32,
    "StatusName": "OnBattery",
    "ShortDescription": "On Battery",
    "Color": "yellow"
  },
  {
    "StatusId": 33,
    "StatusName": "OnSmartBoost",
    "ShortDescription": "On Smart Boost",
    "Color": "yellow"
  },
  {
    "StatusId": 34,
    "StatusName": "TimedSleeping",
    "ShortDescription": "Timed Sleeping",
    "Color": "blue"
  },
  {
    "StatusId": 35,
    "StatusName": "SoftwareBypass",
    "ShortDescription": "Software Bypass",
    "Color": "blue"
  },
  {
    "StatusId": 36,
    "StatusName": "Off",
    "ShortDescription": "Off",
    "Color": "red"
  },
  {
    "StatusId": 37,
    "StatusName": "Rebooting",
    "ShortDescription": "Rebooting",
    "Color": "yellow"
  },
  {
    "StatusId": 38,
    "StatusName": "SwitchedBypass",
    "ShortDescription": "Switched Bypass",
    "Color": "blue"
  },
  {
    "StatusId": 39,
    "StatusName": "HardwareFailureBypass",
    "ShortDescription": "Hardware Failure Bypass",
    "Color": "blue"
  },
  {
    "StatusId": 40,
    "StatusName": "SleepingUntilPowerReturn",
    "ShortDescription": "Sleeping Until Power Return",
    "Color": "blue"
  },
  {
    "StatusId": 41,
    "StatusName": "OnSmartTrim",
    "ShortDescription": "On Smart Trim",
    "Color": "yellow"
  },
  {
    "StatusId": 42,
    "StatusName": "EcoMode",
    "ShortDescription": "Eco Mode",
    "Color": "green"
  },
  {
    "StatusId": 43,
    "StatusName": "HotStandBy",
    "ShortDescription": "Hot Stand By",
    "Color": "green"
  },
  {
    "StatusId": 44,
    "StatusName": "OnBatteryTest",
    "ShortDescription": "On Battery Test",
    "Color": "gray"
  }
 ]