import axios from "axios";
import Cognito from "../utility/CognitoAuth";
import CookieService from "./CookieService";

const base_api =
  process.env.REACT_APP_YETI_API ||
  "https://4jsb2f47s8.execute-api.us-east-1.amazonaws.com/dev/api";

async function defaultRequest() {
  const idToken = await Cognito.getIdToken();
  let req = axios.create({
    baseURL: base_api,
    headers: { Authorization: idToken },
  });

  return req;
}

const YetiClient = {
  /**
   * Axios equivalent, but with YETI parameters automatically injected.
   * 
   * @param {string} path 
   * @param {Object} config 
   * @returns {Promise<AxiosResponse<any>>}
   */
  async get(path, config = {}) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.get(base_api + `/${orgId}${path}`, config);
  },
  /**
  * Wait for a get response when you expect to receive a list and if it requires
  * multiple paginated requests, dynamically wait on a parallel async set of requests,
  * which will then get concatenated into a single response "data.results" array in
  * (the initial response), which is then returned.
  * 
  * @param {string} path 
  * @param {Object} params 
  * @returns {Object<AxiosResponse>}
  */
  async getPaginated(path, config = {}) {
    if (!config.hasOwnProperty('params')) {
      config.params = {};
    }
    if (!config.params.hasOwnProperty('page') || parseInt(config.params?.page) <= 0) {
      config.params.page = 1;
    } else {
      config.params.page = parseInt(config.params.page) + 1;
    }

    if (!config.params.hasOwnProperty('pagesize') || parseInt(config.params?.pagesize) <= 0) {
      config.params.pagesize = 500;
    }

    if (!config.params.hasOwnProperty('cache')) {
      config.params.cache = 1;
    }
    // console.log('--------------------- getPaginatedParallel ', config);

    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    let response = await req.get(base_api + `/${orgId}${path}`, config);

    if (!Array.isArray(response?.data?.results)) {
      throw new Error("Expected array results but received something else.");
    }

    const totalPages = parseInt(response?.data?.totalpages || 1);
    if (typeof response?.data?.nextpage !== "undefined" && response?.data?.nextpage != "" && totalPages > 1) {
      let requests = [];
      for (let i = 2; i <= totalPages; i++) {
        const pConfig = JSON.parse(JSON.stringify(config));
        pConfig.params.page = i;
        const pReq = await defaultRequest();
        requests.push(pReq.get(base_api + `/${orgId}${path}`, pConfig));
        // console.log('--------------------- getPaginatedParallel ', pConfig);
      }
      await Promise.all(requests).then(pResponses => {
        pResponses.map(pResponse => {
          if (!Array.isArray(pResponse?.data?.results)) {
            throw new Error("Expected array results but received something else.");
          }
          response.data.results = response.data.results.concat(pResponse?.data?.results || []); // Probably should throw if results isn't an array
          response.data.totalitems = response.data.results.length;
          response.data.nextpage = pResponse?.data?.nextpage;
          response.data.prevpage = pResponse?.data?.prevpage;
        });
      });
    }

    // console.log('--------------------- getPaginatedParallel ', response);
    return response;
  },
  /**
   * Axios equivalent, but with YETI parameters automatically injected.
   * 
   * @param {string} path 
   * @param {Object} config 
   * @returns {Promise<AxiosResponse<any>>}
   */
  async delete(path, config = {}) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.delete(base_api + `/${orgId}${path}`, config);
  },
  /**
   * Axios equivalent, but with YETI parameters automatically injected.
   * 
   * @param {string} path 
   * @param {Object} config 
   * @returns {Promise<AxiosResponse<any>>}
   */
  async post(path, data, config = {}) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.post(base_api + `/${orgId}${path}`, data, config);
  },
  /**
   * Axios equivalent, but with YETI parameters automatically injected.
   * 
   * @param {string} path 
   * @param {Object} config 
   * @returns {Promise}
   */
  async put(path, data, config = {}) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.put(base_api + `/${orgId}${path}`, data, config);
  },
  /**
   * Axios equivalent, but with YETI parameters automatically injected.
   * 
   * @param {string} path 
   * @param {Object} config 
   * @returns {Promise<AxiosResponse<any>>}
   */
  async patch(path, data, config = {}) {
    const req = await defaultRequest();
    const orgId = CookieService.getCookie("OrganizationId");
    return req.patch(base_api + `/${orgId}${path}`, data, config);
  },
}

export default YetiClient;