import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import React, { useState, useEffect, useMemo } from "react";
import { appState } from "../../AppState";
import Enum from "../../utility/enum.js";
import { hasPolicies } from "../../utility/FeatureToggle";

const DynamicDashboardCardBodyCarousel = (props) => {
  const updateCarouselIndex = (index) => {
    props.setCarouselIndex(index);
  };

  return (
    <Carousel
      selectedItem={props.carouselIndex}
      showStatus={false}
      showThumbs={false}
      onChange={updateCarouselIndex}
      className="carousel-control-dots-abs"
    >
      {props.components.map((comp, index) => (
        <div key={index} className="h-100">
          {comp}
        </div>
      ))}
    </Carousel>
  );
};

const DynamicDashboardCard = (props) => {
  const [stateApp] = appState();
  const [matches, setMatches] = useState(null);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const noCard = useMemo(
    () =>
      typeof props.noCard === "function"
        ? props.noCard(stateApp.env, stateApp.clouds)
        : props.noCard,
    [stateApp.env, stateApp.clouds, props.noCard]
  );

  useEffect(() => {
    const m = props.components.filter((c) => {
      let matchCloud = false;
      let matchEnv = false;
      let matchPolicies = hasPolicies(c.props.policies, stateApp);
      let matchIntegration = true;
      for (let i = 0; i < (c.props.clouds || []).length; i++) {
        if (c.props.clouds[i] === Enum.CloudCategory.ALL) {
          matchCloud = true;
          break;
        }
        if (stateApp.clouds.includes(c.props.clouds[i])) {
          matchCloud = true;
          break;
        }
      }
      for (let i = 0; i < (c.props.envs || []).length; i++) {
        if (
          c.props.envs[i] === Enum.Env.ALL ||
          c.props.envs[i] === stateApp.env
        ) {
          matchEnv = true;
          break;
        }
      }
      for (let i = 0; i < (c.props.integrations || []).length; i++) {
        if (!stateApp.integrations.includes(c.props.integrations[i])) {
          matchIntegration = false;
          break;
        }
      }
      return matchCloud && matchEnv && matchIntegration && matchPolicies;
    });
    setMatches(m);
  }, []);

  const hasContent = matches !== null && matches.length > 0;
  const content =
    hasContent &&
    (matches.length > 1 ? (
      <DynamicDashboardCardBodyCarousel
        components={matches}
        carouselIndex={carouselIndex}
        setCarouselIndex={setCarouselIndex}
      />
    ) : (
      matches[0]
    ));

  return (
    <>
      {hasContent && (
        <div className={props.className}>
          <div className={props.equalHeightCards ? "d-flex h-100" : ""}>
            {noCard ? (
              content
            ) : (
              <Card className="card-chart card-home">
                <CardHeader
                  className="card-header"
                  style={{
                    background: "white",
                    borderBottom: "none",
                    boxShadow: "none",
                  }}
                >
                  <CardTitle className="tria-card-title" tag="h3">
                    {props.title}
                    {matches.length > 1 && (
                      <span className="pull-right">
                        {carouselIndex + 1}/{matches.length}
                      </span>
                    )}
                  </CardTitle>
                </CardHeader>
                <CardBody>{content}</CardBody>
              </Card>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const DynamicDashboardRow = (props) => {
  return (
    <>
      {props.cards.map((c, i) => {
        if (c.nestedConfig) {
          return (
            <div key={i} className={c.nestedConfig[0].className}>
              <DynamicDashboardComponents config={c.nestedConfig} />
            </div>
          );
        } else {
          return (
            <DynamicDashboardCard
              key={i}
              title={c.title}
              className={c.className}
              components={c.components}
              noCard={c.noCard}
              equalHeightCards={props.equalHeightCards}
            />
          );
        }
      })}
    </>
  );
};

const DynamicDashboardComponents = (props) => {
  const components = props.config.map((c, i) => (
    <DynamicDashboardRow
      policies={c.policies || []}
      cards={c.cards}
      key={i}
      equalHeightCards={c.equalHeightCards}
    />
  ));
  return <>{components}</>;
};

export { DynamicDashboardComponents };
