import "../../assets/css/login-box.css";
import { useState, useEffect } from "react";
import React from "react";
import CookieService from "../../services/CookieService";
import ChatService from "../../services/ChatService";
import { useHistory } from "react-router-dom";
import PublicCloudService from "../../public_cloud/services/service";
import UserService from "../../services/UserService";
import IBMService from "../../services/IBMService";
import OrganizationService from "../../services/OrganizationService";
import BlueMatadorService from "../../services/BlueMatadorService";
import CloudWatchService from "../../services/CloudWatchService";
import TrendMicroService from "../../services/TrendMicroService";
import PortalService from "../../services/PortalService";
import axios from "axios";
import { sortBy, compose, prop, toLower } from 'ramda';
import { ACCOUNTS_URL } from "../../config";
import { appState } from "../../AppState";
import Enum from "../../utility/enum";
import {
  Button,
  Input,
  Card,
  CardBody,
  Alert
} from "reactstrap";
import { parseQueryParams } from "../../utility/misc";
import BrowserSupport from "../../utility/BrowserSupport"

const Login = () => {
  // redirect to accounts with return param
  const queryParams = location.search;
  window.location.href = ACCOUNTS_URL + '/sign-in?next=https://' + window.location.hostname + queryParams;

  const [stateApp, stateAppActions] = appState();

  const [errorVisible, setErrorVisible] = useState(false);
  const [inFlight, setInFlight] = useState(false);
  const [returnUrl, setReturnUrl] = useState();
  const [loginFlow, setLoginflow] = useState(null);
  const [showMfa, setShowMfa] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Invalid Username/Password");
  const history = useHistory();

  const [stateLocal, setLocalState] = useState({
    email: "",
    password: "",
    mfa: ""
  });

  useEffect(() => {
    var queryDict = parseQueryParams();
    setReturnUrl(queryDict["rurl"]);
  }, []);

  useEffect(() => {
    document.body.classList.add("background-blue");
    return () => document.body.classList.remove("background-blue");
  });

  const validateForm = () => {
    if (stateLocal.email && stateLocal.password) return true;
  };

  const setEmail = email => setLocalState(values => ({ ...values, email }));

  const setMfa = mfa => setLocalState(values => ({ ...values, mfa }));

  const setPassword = password => setLocalState(values => ({ ...values, password }));

  const doLogin = result => {
    return new Promise((resolve, reject) => {
      stateAppActions.setAuthenticated(result.data);
      resolve();
    });
  };
  async function determineClouds() {
    let ibmi = {}, params = {headers: {"x-auth-token": CookieService.getCookie("session_id")}};
    let cloud_types = await PublicCloudService.list_clouds(params);
    try {
      ibmi = await IBMService.hosts(params);
    } catch {
      ibmi = {data: []};
    }

    let types = [];
    for (var i = 0; i < cloud_types.data.clouds.length; i++) {
      if (cloud_types.data.clouds[i].cloud_type_id.includes("AWS")) {
        types.push(Enum.CloudCategory.AWS);
        types.push(Enum.CloudCategory.PUBLIC_CLOUD);
      }
      if (cloud_types.data.clouds[i].cloud_type_id.includes("AZURE")) {
        types.push(Enum.CloudCategory.AZURE);
        types.push(Enum.CloudCategory.PUBLIC_CLOUD);
      }
      if (cloud_types.data.clouds[i].cloud_type_id.includes("VMWARE")) {
        types.push(Enum.CloudCategory.VMWARE);
      }
    }
    if (ibmi.data.length > 0) types.push(Enum.CloudCategory.IBMI);

    stateAppActions.setClouds([...new Set(types)]);
    stateAppActions.setCloudAccounts(cloud_types.data.clouds);

    return new Promise(resolve => {
      resolve("resolved");
    });
  }

  async function determineOrgs() {
    try{
      let orgs = await OrganizationService.list_orgs({
        headers: {
          "x-auth-token": CookieService.getCookie("session_id")
        }
      });
      stateAppActions.setOrganizations(sortBy(compose(toLower, prop('name')))(orgs.data));
      return new Promise(resolve => {
        resolve("resolved");
      });
    }
    catch(ex){
      console.log(ex);
    }
  }

  async function determineIntegrations() {
    let integrations = [];
    try {
      let hasBlueMatador = await BlueMatadorService.checkSetup({
        headers: {
          "x-auth-token": CookieService.getCookie("session_id")
        }
      });
      if (hasBlueMatador.data.has_bluematador) integrations.push(Enum.Integration.BLUEMATADOR)

      let hasTrendMicro = await TrendMicroService.checkSetup({
        headers: {
          "x-auth-token": CookieService.getCookie("session_id")
        }
      });
      if (hasTrendMicro.data.has_trendmicro) integrations.push(Enum.Integration.TRENDMICRO)

      let cloudWatchAlarms = await CloudWatchService.getAlarms(
        ['us-east-1', 'us-east-2'],
        {
          headers: {
            "x-auth-token": CookieService.getCookie("session_id")
        }
      });
      if (cloudWatchAlarms.data.clouds_alarms_list.length > 0) integrations.push(Enum.Integration.CLOUDWATCH)

      stateAppActions.setIntegrations(integrations);
      return new Promise(resolve => {
        resolve("resolved");
      });
    }
    catch(ex) {
      console.log(ex);
    }
  }

  async function determineSkyLiftLink() {
    try{
      CookieService.eraseCookie("show_skylift", "/");
      let metadata = await PortalService.getPortalCustomerMeta({ meta_key: 'tria_show_skylift_link' }, {
        headers: {
          "x-auth-token": CookieService.getCookie("session_id")
        }
      });

      let show_skylift = metadata.meta_value == "TRUE" ? 1 : 0;
      CookieService.setCookie("show_skylift", show_skylift, 0, stateApp.env, '/');

      stateAppActions.setPortalCustomerMeta(metadata);
      return new Promise(resolve => {
        resolve("resolved");
      });
    }
    catch(ex){
      console.log(ex);
    }
  }

  async function determineUpsellsEnabled() {
    try{
      let metadata = {};
      let upsellsEnabled = CookieService.getCookie("tria_upsell_ads");
      if (!upsellsEnabled) {
        metadata = await PortalService.getPortalCustomerMeta({ meta_key: 'tria_upsell_ads' }, {
          headers: {
            "x-auth-token": CookieService.getCookie("session_id")
          }
        });
        CookieService.setCookie("tria_upsell_ads", metadata.meta_value, 0, stateApp.env, '/');
      } else {
        metadata = { meta_key: 'tria_upsell_ads', meta_value: upsellsEnabled };
      }

      stateAppActions.setPortalCustomerMeta(metadata);
      return new Promise(resolve => {
        resolve("resolved");
      });
    }
    catch(ex){
      console.log(ex);
    }
  }

  const showErrorMessage = msg => {
    setErrorMessage(msg);
    setErrorVisible(true);
    setTimeout(() => {
      setErrorVisible(false);
    }, 2000);
    setInFlight(false);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setInFlight(true);
    try {
      let result = null;
      switch (e.target.id) {
        case "userpass-submit":
          let userResult = await UserService.iniate(stateLocal.email);
          let passResult = await UserService.local_login(
            stateLocal.password,
            userResult.data.token
          );

          if (passResult.data.hasOwnProperty("user_id")) {
            result = passResult.data;
            break;
          }

          //otherwise mfa store above in state and send to mfa screen //return;
          setLoginflow(values => ({
            ...values,
            userResult: userResult.data,
            passResult: passResult.data,
            step: "v3_tfa_attempt"
          }));
          setInFlight(false);
          setShowMfa(true);
          return;

        case "mfa-submit":
          setInFlight(true);
          try {
            let mfaResult = await UserService.verify_mfa(
              stateLocal.mfa,
              loginFlow.passResult.token
            );
            if (!mfaResult.data.hasOwnProperty("user_id"))
              throw Error("Invalid Code");

            result = mfaResult.data;
          } catch (e) {
            showErrorMessage("Invalid MFA Token");

            return;
          }
          break;

        default:
          break;
      }

      if (typeof result == null) throw Error("Unauthorized");

      if (result.session_id) {
        axios.defaults.headers.common["x-auth-token"] = result.session_id;
        CookieService.setCookie(
          "session_id",
          result.session_id,
          1,
          stateApp.env, '/'
        );
        //CookieService.setCookie("session_timeout", result.data.session_timeout, 1, stateApp.env);
        //CookieService.setCookie("session_timeout_time",1573054868182, 1, stateApp.env);
        let user_info = await UserService.user_info({
          headers: {
            "x-auth-token": CookieService.getCookie("session_id")
          }
        });

        let session_timeout_time = await UserService.get_session_timeout_time();
        var d = Date.parse(session_timeout_time.data.expiration);
        CookieService.setCookie("session_timeout_time", d, 1, stateApp.env);
        stateAppActions.setSessionTimeoutTime(d);

        await Promise.all([
          determineClouds(),
          determineOrgs(),
          determineIntegrations(),
          determineSkyLiftLink(),
          determineUpsellsEnabled(),
        ])
        .then() // 1,Error: 2,3
        .catch(e => console.log(e));

        //determin what clouds a customer has
        await doLogin(user_info);
        ChatService.getChatCustomer(result.user_name);
        setInFlight(false);

        const queryParams = location.search;

        if (returnUrl != null) {
          history.push(returnUrl + queryParams);
        } else {
          history.push("/app/dashboard"+queryParams);
        }
      }
    } catch (error) {
      console.log(error);
      showErrorMessage("Invalid Username/Password");
    }
  };

  return (
    <>
   
    </>
  );
};
const ForgotPasswordScreen = props => {
  return (
    <div>
      <p>
        For your convenience Portal and TRiA use the same password. To reset
        your Portal password click the link below.{" "}
      </p>
      {/* <ol>
  <li>Click the link to reset password.</li>
  <li>A "Password pass phrase" will be displayed on the screen (note the phrase). An email will be sent.</li>
  <li>Open "Connectria Portal Password Reset" email and click the link to reset your password. You will be required to enter the "Password Pass phrase".</li>
  <li>Password Must Contain all the following: 10 -40 characters at least 1 upper-case character at least 1 number at least 1 special character.</li>
</ol> */}
      <p className="pb-2">
        <a
          href="https://www.mh.connectria.com/account/forgotpassword"
          target="_blank"
        >
          Click Here To Reset Password
        </a>
      </p>
      <p className="lead">
      If you have any questions please feel free to reach out to us via Chat located in the lower right area of the screen or call <a href="tel:+18007817178">+1-800-781-7178 </a>
      or email us at <a href="mailto:support@connectria.com">support@connectria.com <i className="fa fa-envelope" aria-hidden="true"></i>.</a>
      </p>
      <br></br>
      <a href="#" onClick={() => props.setShowForgotPassword(false)}>
        <i className="fas fa-arrow-circle-left"></i> Back To Login
      </a>
    </div>
  );
};
const MfaForm = props => {
  return (
    <form onSubmit={props.handleSubmit}>
      <label>MFA Token</label>
      <Input
        autoFocus
        type="input"
        pattern="\d{1,5}"
        value={props.stateLocal.mfa}
        onChange={e => props.setMfa(e.target.value)}
        placeholder="Enter MFA"
      />
      <Button
        block
        className="mt-3"
        disabled={!props.validateForm() || props.inFlight}
        onClick={props.handleSubmit}
        id="mfa-submit"
        type="submit"
      >
        Submit
      </Button>
    </form>
  );
};

export default Login;
