import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const renderIcon = (iconClass) => {
  if (!iconClass) return null;

  return (
    <i
      className={`mkt-btn-icon ${iconClass}`}
      aria-hidden="true"
    />
  );
};

export function Button({
  children,
  variant = 'primary',
  size = 'md',
  className = '',
  href,
  to,
  icon,
  ...btnProps
}) {
  // Determine the component to render based on props
  const Component = href
    ? 'a'
    : to
      ? Link
      : 'button';

  // Prepare props based on the component type
  const componentProps = {
    ...(Component === 'a'
      ? { href }
      : Component === Link
        ? { to }
        : {}),
    ...btnProps,
    className: `mkt-btn mkt-btn-${variant} mkt-btn-${size} ${className}`.trim(),
  };

  return (
    <Component {...componentProps}>
      {renderIcon(icon)}
      {children}
    </Component>
  )
}

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'ghost', 'link']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
}

Button.defaultProps = {
  variant: 'primary',
  size: 'md',
}