import React, { useState, useEffect, useMemo, useRef, Suspense } from "react";
import { appState } from "../AppState";
import TabbedDashboard from "../components/Dashboard/TabbedDashboard";
import NotificationsWidget from "../components/LightEdgeNotifications/NotificationsWidget.js";
import { DynamicDashboardComponents } from "../components/Dashboard/DynamicDashboard";
import { dashBoardConfig, dashBoardConfigIbmi } from "../routes.js";
import Enum from "../utility/enum.js";
import {
  matchedClouds,
  matchedIntegrations,
  matchedEnv,
  hasPolicies,
} from "../utility/FeatureToggle";

const ResponsiveDashboard = ({ width }) => {
  const [stateApp] = appState();
  const userRoles = stateApp.roles_v2;

  // Determine the original configuration based on cloud type
  const originalConfig = useMemo(() => {
    return stateApp.clouds.includes(Enum.CloudCategory.PUBLIC_CLOUD)
      ? dashBoardConfig
      : dashBoardConfigIbmi;
  }, [stateApp.clouds]);

  const filteredConfig = useMemo(() => {
    if (width === 0) {
      return originalConfig;
    }

    // Bypass filtering for super admins or OrgAdmins
    if (
      stateApp.superAdmin ||
      userRoles.some((role) => role.RoleName === "OrgAdmin")
    ) {
      return originalConfig;
    }

    // Filter the configuration
    return originalConfig.map((config) => {
      const filteredCards = config.cards
        .map((card) => {
          const filteredComponents = (card.components || []).filter((comp) => {
            const { clouds, integrations, policies, envs } = comp.props;
            return (
              matchedClouds(clouds) &&
              matchedIntegrations(integrations) &&
              hasPolicies(policies, stateApp) &&
              matchedEnv(stateApp, envs)
            );
          });
          return { ...card, components: filteredComponents };
        })
        .filter((card) => card.components.length > 0);

      return { ...config, cards: filteredCards };
    });
  }, [originalConfig, stateApp, userRoles, width]);

  return width <= 768 ? (
    <div id="db-pane">
      <TabbedDashboard>
        <DynamicDashboardComponents config={filteredConfig} />
      </TabbedDashboard>
    </div>
  ) : (
    <div id="db-pane">
      <div className="row">
        <DynamicDashboardComponents config={filteredConfig} />
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [width, setWidth] = useState(window.innerWidth || 0);
  const timerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        setWidth(window.innerWidth);
      }, 300);
    };

    window.addEventListener("resize", handleResize);
    // Set initial width
    setWidth(window.innerWidth);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <Suspense fallback={<div></div>}>
      <NotificationsWidget />
      <ResponsiveDashboard width={width} />
    </Suspense>
  );
};

export default Dashboard;
