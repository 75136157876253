import React from 'react';

/**
 * @param {Object} props
 * @param {string} [props.id] - Input id attribute
 * @param {string} [props.name] - Input name attribute
 * @param {string} [props.type='text'] - Input type (text, email, password, etc.)
 * @param {string} [props.placeholder] - Input placeholder text
 * @param {string} [props.value] - Input value
 * @param {Function} [props.onChange] - Change handler function
 * @param {boolean} [props.disabled] - Whether the input is disabled
 * @param {string} [props.size='md'] - Size of the input ('sm', 'md', 'lg')
 * @param {string} [props.status] - Status for validation styling ('success', 'error')
 * @param {string} [props.className] - Additional CSS classes
 */
export function Input({
  id,
  name,
  type = 'text',
  placeholder,
  value,
  onChange,
  disabled = false,
  size = 'md',
  status,
  className = '',
  shouldHideLabel = false,
  ...rest
}) {
  const sizeClass = size ? `mkt-form-input-${size}` : '';
  const statusClass = status ? `mkt-form-input-${status}` : '';
  return (
    <input
      name={name}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={`mkt-form-input ${sizeClass} ${statusClass} ${className}`}
      {...rest}
    />
  );
};
